<template>
  <div>
    
    <b-modal ref="show-term" size="lg" :title="selected_term ? selected_term.name : ''">
      <p v-if="selected_term" class="my-4" :html="selected_term.template.template" v-html="selected_term.template.template"></p>
    </b-modal>

    <v-row class="mt-4">
      <v-col lg="6">
        
        <template v-for="(term, i) in form_page.terms">
          <TermsCheckbox
            class="my-2"
            v-bind:key="i"
            :term="term"
            @selected="term_checkbox_input"
            @showTerm="show_term_clicked"
          />
        </template>

      </v-col>
    </v-row>
    
    <p v-if="show_error" class="mt-8" style="color: #fd397a;">{{ $t('PUBLIC.FORM.MISSING_FIELDS') }}</p>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
      <b-button
        v-if="form_page.index != 0"
        class="mt-4 mr-4"
        variant="outline-secondary"
        @click.prevent="on_back"
      >
        {{ $t('COMMON.BACK') }}
      </b-button>
      
      <b-button
        class="mt-4"
        variant="primary"
        :type="type"
        :disabled="disabled || spinning"
        @click.prevent="on_click"
      >
        <memlist-spinner v-if="spinning" />
        {{ $t('COMMON.CONFIRM') }}
      </b-button>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import TermsCheckbox from '@/view/pages/members/register/registerwizard/terms/TermsCheckbox.vue';

export default {
  name: 'FormPagePersonnr',
  mixins: [ toasts ],

  components: {
    TermsCheckbox
  },

  props: {
    
    form_page_data: { /* data for the entire form, previously entered, all pages */
      type: Object,
      default: () => ({})
    },
    form_page: { /* page definition for this page, ml_form_page */
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      local_form: {},

      selected_term: null,

      term_inputs: [],

      skip: false,

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  },
  mounted() {

    this.local_form = { ...this.form_page_data }

    this.check_mandatory_terms_against_inputs();
  },
  watch: {
    
  },
  methods: {

    check_mandatory_terms_against_inputs() {
      const mandatory_terms = this.form_page.terms.filter(item => item.is_mandatory);
      const accepted_terms = this.term_inputs.filter(item => item.is_accepted);

      for (const mandatory of mandatory_terms) {
        if (!accepted_terms.map((item) => item.id).includes(mandatory.id)) {
          this.disabled = true;
          return false;
        }
      }

      this.disabled = false;
      
      return true;
    },

    term_checkbox_input(id, is_accepted) {
      this.term_inputs = this.term_inputs.filter(item => item.id !== id);

      this.term_inputs.push({ id, is_accepted });

      this.check_mandatory_terms_against_inputs();
    },

    show_term_clicked(id) {
      this.selected_term = this.form_page.terms.find(item => item.id === id);

      this.$nextTick(()=>{
        this.$refs['show-term'].show();
      });
      
    },

    update_form(field_name, value) {
      this.local_form[field_name] = value;

      this.local_form = { ...this.local_form };
    },
    validated(field_name, value, valid) {
      this.local_form[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    on_back() {
      this.$emit('back', this.form_page, this.local_form_page_data);
    },


    async on_click() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(()=>{
        if (this.is_form_valid) {
          this.spinning = false;

          this.$emit('next', this.form_page, this.local_form);
        }
        else {
          this.show_error = true; 

          setTimeout(()=>{
            this.spinning = false;
          }, 2000);
        }
      });
    },

  }
};
</script>
